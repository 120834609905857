<template>
  <div class="regionSetting" style="text-align: left;">
    <van-nav-bar
        title="区域设置"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <br /><br />
    <van-form @submit="onSubmit">
      <van-field
          readonly
          clickable
          class="areaClass"
          name="area"
          :value="areaStr"
          label="省市区域"
      />
      <van-field
          readonly
          clickable
          name="regionManager"
          :value="regionManager"
          label="区域经理"
          placeholder="点击选择区域经理"
          @click="showRegionManager = true"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交修改</van-button>
      </div>
    </van-form>

    <van-popup v-model="showRegionManager" position="bottom">
      <van-picker
          show-toolbar
          :columns="regionManagerOptions"
          @confirm="onConfirmRegionManager"
          @cancel="showRegionManager = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {getAreaList, getRegionManagerOptions, getInfo, updateRegionManager} from "@/api/login";
import {Toast} from "vant";

export default {
  name: "RegionSetting",
  components: {},
  data() {
    return {
      phone: '',
      oldRegionManagerId: null,
      areaStr: '',
      areaArrVal: [],
      regionManager: '',
      regionManagerId: null,
      regionManagerOptions: [],
      regionManagerOptionsVal: [],
      showRegionManager: false,
      areaList: [],
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.areaList = getAreaList();
      getInfo().then(r => {
        console.log(r);
        this.phone = r.data.phone;
        let status = r.data.status;
        // 临时账号 还未设置
        if(status == -9) {
          // 回去设置吧（一般不会出现）
          this.$router.push({path: '/setRegionManager',query: { phone: this.phone, status: status }});
          return;
        }
        let province_list = this.areaList.province_list;
        let city_list = this.areaList.city_list;
        let county_list = this.areaList.county_list;
        let provinceName = province_list[r.data.provinceCode];
        let cityName = city_list[r.data.cityCode];
        let regionName = county_list[r.data.regionCode];
        if(cityName.startsWith(provinceName)) {
          this.areaStr = cityName + '/' + regionName;
        } else {
          this.areaStr = provinceName + '/' + cityName + '/' + regionName;
        }

        this.oldRegionManagerId = r.data.superiorId;
        this.regionManagerId = r.data.superiorId;

        // 区域经理选择项
        let params = { provinceCode: r.data.provinceCode, cityCode: r.data.cityCode, regionCode: r.data.regionCode }
        getRegionManagerOptions(params).then(response => {
          let data = response.data;
          console.log(data);
          this.regionManagerOptions = [];
          this.regionManagerOptionsVal = [];
          // this.regionManager = '';
          // this.regionManagerId = null;
          for(let i in data) {
            console.log(data[i]);
            let showName;
            if(data[i].nickName) {
              showName = data[i].nickName + '(' + data[i].username + ')';
            } else {
              showName = data[i].username;
            }
            this.regionManagerOptions.push(showName);
            this.regionManagerOptionsVal[showName] = data[i].userId;

            // console.log(this.regionManagerId);
            if(data[i].userId == this.regionManagerId) {
              this.regionManager = showName;
            }
          }
        });
      });
    },
    onConfirmRegionManager(val) {
      //console.log(val);
      //console.log(this.regionManagerOptionsVal[val]);
      this.regionManager = val;
      this.regionManagerId = this.regionManagerOptionsVal[val];
      this.showRegionManager = false;
    },
    onSubmit(f) {
      // 提交修改 后台需要进入修改审核状态
      console.log(f);
      // this.regionManagerId
      if(this.oldRegionManagerId == this.regionManagerId) {
        Toast.fail("未发现修改.");
      } else {
        let params = { regionManagerId: this.regionManagerId };
        console.log(params);
        updateRegionManager(params).then(response => {
          console.log(response);
          // 跳至待审核
          this.$router.push({path: '/checkPending',query: { phone: this.phone, status: -1 }});
        });
      }
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
